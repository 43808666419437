<template>
    <div class="EM">
        <button class="location-button" @click="getMyLocation()">
            Get My Location
        </button>
        <div class="desc-text">
            <p>
                Then, you can either click the "Get My Location" button to get
                your current location, or you can enter a latitude and longitude
                in the fields below. The marker on the map can be dragged to
                update the coordinates.
            </p>
        </div>
        <div style="padding-top: 10px;" class="coordinate-input">
            <custom-floating-label
                :config="{
                    label: 'Latitude',
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }"
            >
                <input v-model.number="sampleLat" type="number" />
            </custom-floating-label>
        </div>
        <div class="coordinate-input">
            <custom-floating-label
                :config="{
                    label: 'Longitude',
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }"
            >
                <input v-model.number="sampleLong" type="number" />
            </custom-floating-label>
        </div>
        <div id="previous-sample">
            <previous-sample-table :sample="sample" v-if="sample != ''" />
        </div>
        <div
            v-if="
                (!isFidGrid && sampleLat !== null && sampleLong !== null) ||
                    (isFidGrid &&
                        inFidGrid &&
                        sampleLat !== null &&
                        sampleLong !== null)
            "
        >
            <div
                v-if="
                    newParameters !== null &&
                        newParameters !== undefined &&
                        newParameters.length > 0
                "
                id="new-sample-entries"
            >
                <span class="sample-header">New Sample</span>
                <div
                    v-for="(newParam, idx) in newParameters"
                    :key="'input-' + idx"
                    class="coordinate-input"
                >
                    <custom-floating-label
                        :config="{
                            label:
                                newParam.Analyte + ' (' + newParam.Unit + ')',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                    >
                        <input v-model.number="newParam.Value" type="number" />
                    </custom-floating-label>
                </div>
                <button class="location-button" @click="saveSample()">
                    Save Sample
                </button>
            </div>
            <!-- show text if there are not any analytes configured for the project -->
        </div>
        <div v-else-if="!inFidGrid" id="no-analytes-text" class="desc-text">
            <p>
                Location is not within the FID Grid. Please move within the grid
                or retry location.
            </p>
        </div>
        <div v-else id="no-analytes-text" class="desc-text">
            <p>
                No analytes have been configured for this project. Please
                contact an administrator to add analytes to this project.
            </p>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import CustomFloatingLabel from '../../CustomFloatingLabel.vue';
import { dataCollectionService } from '../../../services/dataCollection.service.js';
import PreviousSampleTable from './PreviousSampleTable.vue';

export default {
    name: 'DataCollectionSidebar',
    components: { CustomFloatingLabel, PreviousSampleTable },
    props: {},
    data() {
        return {
            sample: null,
            newParameters: null,
            isFidGrid: false,
            projectAnalytes: [],
            inFidGrid: false,
        };
    },
    created() {
        this.resetSidebar();
    },
    mounted() {},
    methods: {
        ...mapMutations('dataCollection', [
            'setSampleLat',
            'setSampleLong',
            'setGetCoordinatesFlag',
        ]),
        ...mapActions('dataCollection', ['setDCRefreshMap']),

        // check if the analytes contain FID, we use different logic for saving the sample
        async checkIsFIDGrid() {
            dataCollectionService.isFidGrid(this.ProjectID).then((res) => {
                this.isFidGrid = res.data;
                if (this.isFidGrid) {
                    this.newParameters = [
                        {
                            Analyte: 'FID',
                            Unit: 'ppm',
                            Value: null,
                        },
                    ];
                }
            });
        },
        async getAnalytes() {
            dataCollectionService
                .getProjectAnalytes(this.ProjectID)
                .then((res) => {
                    this.projectAnalytes = res.data;
                    this.newParameters = this.projectAnalytes.map((analyte) => {
                        return {
                            Analyte: analyte.AnalyteName,
                            Unit: analyte.UnitName,
                            Value: null,
                        };
                    });
                });
        },
        resetSidebar() {
            this.setSampleLat(null);
            this.setSampleLong(null);
            this.getAnalytes();
            this.checkIsFIDGrid().then(() => {
                this.getAnalytes();
            });
        },
        // update the flag to get the users's current location
        getMyLocation() {
            // this.setSampleLat(43.100907);
            // this.setSampleLong(-78.937515);
            this.setGetCoordinatesFlag();
            this.getPreviousSample();
        },
        // get the previous sample from the database
        getPreviousSample() {
            if (this.sampleLat && this.sampleLong) {
                dataCollectionService
                    .getRelatedGeometry(
                        this.ProjectID,
                        this.sampleLat,
                        this.sampleLong
                    )
                    .then((res) => {
                        if (res.data.Parameters[0].Value != null) {
                            this.sample = JSON.parse(JSON.stringify(res.data));
                            this.newParameters = res.data.Parameters;
                            this.inFidGrid = true;
                        } else if (!res.data && this.isFidGrid) {
                            this.inFidGrid;
                        }
                    });
            }
        },
        async saveSample() {
            if (this.isFidGrid) {
                await dataCollectionService.insertRelatedSample(
                    this.ProjectID,
                    this.sampleLat,
                    this.sampleLong,
                    this.newParameters
                );
            } else {
                await dataCollectionService.insertSample(
                    this.ProjectID,
                    this.sampleLat,
                    this.sampleLong,
                    this.newParameters
                );
            }
            this.forceMapUpdate();
        },
        forceMapUpdate() {
            this.setDCRefreshMap(true);
        },
    },
    computed: {
        ...mapGetters('projects', ['ProjectID']),
        ...mapState('dataCollection', ['sampleLat', 'sampleLong']),
    },
    watch: {
        ProjectID(newVal, oldVal) {
            this.resetSidebar();
        },
        sampleLat(newVal, oldVal) {
            if (newVal != oldVal) {
                if (this.isFidGrid) {
                    this.getPreviousSample();
                }
            }
        },
    },
};
</script>

<style scoped>
.EM {
    width: 320px !important;
}

.desc-text {
    margin: -8px 5%;
    width: 320px;
}

.location-button {
    background-color: #006395;
    color: white;
    display: block;
    margin: 16px 5% 16px;
    width: 320px;
    color: white;
    border-radius: 3px;
    border: 0;
    height: 48px;
    padding: 11px 10px;
}

.coordinate-input {
    position: relative;
    margin: 0px 5%;
    width: 320px;
}
/* can use the >>> to use this scoped style in child components */
>>> .sample-header {
    width: 320px;
    margin-left: 5%;
}

td {
    padding: 4px;
}

.tr-0 {
    background-color: white;
}
.tr-1 {
    background-color: #f2f2f2;
}
</style>
