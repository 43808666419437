<template>
    <div class="previous-sample-info" v-if="sample !== null">
        <span class="sample-header">Previous Sample</span>
        <table class="sample-table">
            <tr class="tr-0">
                <td>Sampler</td>
                <td>{{ sample.Sampler }}</td>
            </tr>
            <tr class="tr-1">
                <td>Sample Date</td>
                <td>{{ sample.SampleDate }}</td>
            </tr>
            <tr
                v-for="(param, idx) in sample.Parameters"
                :key="idx"
                :class="'tr-' + (idx % 2)"
            >
                <td>{{ param.Analyte }}</td>
                <td>{{ param.Value + param.Unit }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    name: 'PreviousSampleTable',
    components: {},
    props: {
        sample: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {};
    },
    created() {},
    mounted() {},
    methods: {},
    computed: {},
};
</script>

<style scoped>
.sample-table {
    margin: 0px 5% 16px;
    width: 320px;
    border: 1px #c6c6c6 solid;
    border-radius: 3px;
}
</style>
