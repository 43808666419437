import { apiConstants } from '../common/apiConst';
import { request } from './request';

export const dataCollectionService = {
    getRelatedGeometry,
    insertSample,
    insertRelatedSample,
    isFidGrid,
    getProjectAnalytes,
};

function getRelatedGeometry(projectId, latitude, longitude) {
    return request({
        url:
            apiConstants.getRelatedGeometry +
            '?projectId=' +
            projectId +
            '&latitude=' +
            latitude +
            '&longitude=' +
            longitude,
        method: 'get',
    });
}

function insertSample(projectId, latitude, longitude, samples) {
    let body = {
        projectId: projectId,
        latitude: latitude,
        longitude: longitude,
        parameters: samples,
    };
    return request({
        url: apiConstants.addDataCollectionSample,
        data: body,
        method: 'post',
    });
}

function insertRelatedSample(projectId, latitude, longitude, samples) {
    let body = {
        projectId: projectId,
        latitude: latitude,
        longitude: longitude,
        parameters: samples,
    };
    return request({
        url: apiConstants.addRelatedSample,
        data: body,
        method: 'post',
    });
}

function isFidGrid(projectId) {
    return request({
        url: apiConstants.isFidGrid + '?projectId=' + projectId,
        method: 'get',
    });
}

function getProjectAnalytes(projectId) {
    return request({
        url: apiConstants.getProjectAnalytes + '?projectId=' + projectId,
        method: 'get',
    });
}
