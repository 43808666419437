var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"EM"},[_c('button',{staticClass:"location-button",on:{"click":function($event){return _vm.getMyLocation()}}},[_vm._v(" Get My Location ")]),_vm._m(0),_c('div',{staticClass:"coordinate-input",staticStyle:{"padding-top":"10px"}},[_c('custom-floating-label',{attrs:{"config":{
                label: 'Latitude',
                name: 'wrapper',
                line: false,
                scale: false,
            }}},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.sampleLat),expression:"sampleLat",modifiers:{"number":true}}],attrs:{"type":"number"},domProps:{"value":(_vm.sampleLat)},on:{"input":function($event){if($event.target.composing){ return; }_vm.sampleLat=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}})])],1),_c('div',{staticClass:"coordinate-input"},[_c('custom-floating-label',{attrs:{"config":{
                label: 'Longitude',
                name: 'wrapper',
                line: false,
                scale: false,
            }}},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.sampleLong),expression:"sampleLong",modifiers:{"number":true}}],attrs:{"type":"number"},domProps:{"value":(_vm.sampleLong)},on:{"input":function($event){if($event.target.composing){ return; }_vm.sampleLong=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}})])],1),_c('div',{attrs:{"id":"previous-sample"}},[(_vm.sample != '')?_c('previous-sample-table',{attrs:{"sample":_vm.sample}}):_vm._e()],1),(
            (!_vm.isFidGrid && _vm.sampleLat !== null && _vm.sampleLong !== null) ||
                (_vm.isFidGrid &&
                    _vm.inFidGrid &&
                    _vm.sampleLat !== null &&
                    _vm.sampleLong !== null)
        )?_c('div',[(
                _vm.newParameters !== null &&
                    _vm.newParameters !== undefined &&
                    _vm.newParameters.length > 0
            )?_c('div',{attrs:{"id":"new-sample-entries"}},[_c('span',{staticClass:"sample-header"},[_vm._v("New Sample")]),_vm._l((_vm.newParameters),function(newParam,idx){return _c('div',{key:'input-' + idx,staticClass:"coordinate-input"},[_c('custom-floating-label',{attrs:{"config":{
                        label:
                            newParam.Analyte + ' (' + newParam.Unit + ')',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }}},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(newParam.Value),expression:"newParam.Value",modifiers:{"number":true}}],attrs:{"type":"number"},domProps:{"value":(newParam.Value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(newParam, "Value", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])],1)}),_c('button',{staticClass:"location-button",on:{"click":function($event){return _vm.saveSample()}}},[_vm._v(" Save Sample ")])],2):_vm._e()]):(!_vm.inFidGrid)?_c('div',{staticClass:"desc-text",attrs:{"id":"no-analytes-text"}},[_c('p',[_vm._v(" Location is not within the FID Grid. Please move within the grid or retry location. ")])]):_c('div',{staticClass:"desc-text",attrs:{"id":"no-analytes-text"}},[_c('p',[_vm._v(" No analytes have been configured for this project. Please contact an administrator to add analytes to this project. ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"desc-text"},[_c('p',[_vm._v(" Then, you can either click the \"Get My Location\" button to get your current location, or you can enter a latitude and longitude in the fields below. The marker on the map can be dragged to update the coordinates. ")])])}]

export { render, staticRenderFns }